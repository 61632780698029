<template>
  <div>
    <h4>
      {{ title }}:
      <span class="text-primary"
        ><b>{{ equipment.nome }}</b></span
      >
    </h4>
    <div class="box-body">
      <div class="overlay overlay-local" v-if="busy">
        <i class="fa fa-refresh fa-spin"></i>
      </div>
      <EquipmentAlarmConfigurationTable
        v-bind:equipment="equipment"
        v-bind:display="display"
        v-bind:panelOptions="panelOptions"
        v-bind:alarms="alarms"
        v-bind:full="false"
        v-on:notificationButtonClicked="setNotificationAction"
        v-on:editButtonClicked="setEditAction"
        order="name"
      >
      </EquipmentAlarmConfigurationTable>
      <EquipmentAlarmNotificationForm
        v-if="alarm && action == 'notification'"
        v-bind:alarms="[alarm]"
        v-bind:defaultOptions="defaultOptions"
        v-bind:panel="panel"
        v-on:hide="resetAlarm"
        v-on:refresh="fetchEquipmentDataAlarmsCfg"
      >
      </EquipmentAlarmNotificationForm>
      <EquipmentAlarmEditorForm
        v-if="alarm && action == 'edition'"
        v-bind:alarms="[alarm]"
        v-bind:defaultOptions="defaultOptions"
        v-bind:panel="panel"
        v-on:hide="resetAlarm"
        v-on:refresh="fetchEquipmentDataAlarmsCfg"
      >
      </EquipmentAlarmEditorForm>
    </div>
  </div>
</template>

<script>
import EquipmentAlarmNotificationForm from "@/components/equipment-alarm-notification-form";
import EquipmentAlarmEditorForm from "@/components/equipment-alarm-editor-form";
import EquipmentAlarmConfigurationTable from "@/components/equipment-alarm-configuration-table";

export default {
  name: "EquipmentAlarmNotificationPanel",
  components: {
    EquipmentAlarmNotificationForm,
    EquipmentAlarmEditorForm,
    EquipmentAlarmConfigurationTable
  },
  props: {
    equipment: {
      type: Object,
      required: true
    },
    display: {
      type: Object,
      required: true
    },
    panel: {
      type: Object,
      required: false,
      default: () => {}
    },
    defaultOptions: {
      type: Object,
      required: false,
      default: () => ({ timeout: 5, groups: [] })
    }
  },
  data: function() {
    return {
      action: "",
      alarm: null,
      busy: false
    };
  },
  computed: {
    title: function() {
      return this.$t("alarm_configuration");
    },
    equipmentAlarmList() {
      return this.$store.getters["dashboard/alarmList"]
        ? this.$store.getters["dashboard/alarmListFrom"](this.equipment?.id) ||
            []
        : [];
    },
    alarms() {
      let lst = JSON.parse(JSON.stringify(this.equipmentAlarmList));
      lst.sort((a, b) => {
        var aa = this.$utils.sprintf(
          "%d%012d",
          a?.alarm_current_state?.state ? 0 : 1,
          new Date(
            a?.alarm_current_state?.datetime_last_activation || null
          ).getTime()
        );
        var bb = this.$utils.sprintf(
          "%d%012d",
          b?.alarm_current_state?.state ? 0 : 1,
          new Date(
            b?.alarm_current_state?.datetime_last_activation || null
          ).getTime()
        );
        if (aa < bb) return -1;
        if (aa > bb) return 1;
        return 0;
      });
      return lst;
    },
    panelOptions: function(name) {
      return (this.panel && this.panel.options) || null;
    }
  },
  methods: {
    fetchEquipmentDataAlarmsCfg: function() {
      var query = {
        resource: "alarm",
        connectorId: this.equipment.id,
        forceUpdate: true,
        mode: "full"
      };
      this.busy = true;
      this.$store.dispatch("dashboard/fetchResourcesFrom", query).then(() => {
        this.busy = false;
      });
    },
    setNotificationAction(alarm) {
      this.execute("notification", alarm);
    },
    setEditAction(alarm) {
      this.execute("edition", alarm);
    },
    execute: function(action, alarm) {
      this.action = action;
      this.alarm = alarm;
    },
    resetAlarm: function() {
      this.action = "";
      this.alarm = null;
    }
  },
  created: function() {
    this.fetchEquipmentDataAlarmsCfg();
  }
};
</script>

<style scoped></style>
